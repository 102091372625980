<template>
  <v-container fluid>
    <TableViewComponent
      title="Table transmission > objet / sous-objet"
      @addItemEvent="onAddElement()"
      addItemLabel="ajouter un objet"
      itemLabel="objet"
      itemsLabel="objets"
      :items="items"
      :columns="buildColumns()"
      deleteItemLabel="Voulez-vous supprimer ce type de transmission?"
      :pagination="false"
      :loading="loading"
      :rolesForEdition="rolesForEdition"
      :rolesForAddDelete="rolesForAddDelete"
      :rolesForRead="rolesForRead"
    >
      <template v-slot:aftertable v-if="canEdit">
        <v-card flat outlined class="my-4 py-4">
          <div class="d-flex align-center">
            <div class="ml-2 mr-10 mb-2 text-subtitle-1">
              Édition de la matrice des destinataires
            </div>
          </div>

          <v-row no-gutters>
            <v-col cols="7" class="ml-4">
              <div>1- Créez un nouvel objet en cliquant sur "Ajouter un objet"</div>
              <div>2- Renseignez les champs et ajoutez les sous-objets associés (cliquez bien sur le + pour enregistrer un sous-objet)</div>
              <div>3- Cliquez sur "Exporter la matrice"</div>
              <div>4- Cliquez sur "Ouvrir le Gsheet" et configurez les objets/sous-objets nouvellement créés</div>
              <div>5- Cliquez sur "Importer la matrice"</div>
            </v-col> 

            <v-col cols="4" class="justify-center">
              <!-- Bouton d'export de matrice -->
              <div class="d-flex align-center justify-center">
                <v-btn
                  class="ml-4 btn"
                  outlined
                  color="primary"
                  @click="exportMatrix"
                  :disabled="running"
                  :loading="running"
                >
                  Exporter la matrice
                </v-btn>
              </div>

              <!-- Bouton pour ouvrir le gsheet cible -->
              <div class="d-flex align-center justify-center mt-4">
                <v-btn
                  class="ml-4 btn px-7"
                  outlined
                  color="primary"
                  :disabled="running"
                  :loading="running"
                  link
                  :href="urlGsheet"
                  target="_blank"
                >
                  Ouvrir le gsheet
                </v-btn>
              </div>

              <!-- Bouton d'import de matrice -->
              <div class="d-flex align-center justify-center mt-4">
                <v-btn
                  class="ml-4 btn"
                  outlined
                  color="primary"
                  @click="showConfirmImport = true"
                  :disabled="running"
                  :loading="running"
                >
                  Importer la matrice
                </v-btn>
              </div>
            </v-col>
          </v-row>
    
          <!-- Message de confirmation ou d'alerte -->
          <v-card-text v-if="failed">
            <v-alert type="error" border="left" text
              ><div style="opacity: 1">
                <span>{{ failure }}</span>
              </div>
              <div v-for="(e, i) in failureMessages" :key="i">
                <span>- {{ e }}</span>
              </div>
            </v-alert>
          </v-card-text>

          <v-card-text v-if="success">
            <v-alert type="info" border="left" text color="#E22F92"
              ><div style="opacity: 1">
                <span>{{ successMessage }}</span>
              </div>
            </v-alert>
          </v-card-text>

          <v-dialog
            v-model="showConfirmImport"
            :overlay="false"
            max-width="500px"
            transition="dialog-transition"
          >
            <v-card>
              <v-card-title class="headline grey lighten-2">
                Importer la matrice
              </v-card-title>

              <v-card-text class="mt-4">
                Veuillez vérifier votre matrice dans le fichier gsheet avant de
                lancer l'import. Cet import écrasera les données dans la
                Database Globale.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="confirmImportMatrix">
                  LANCER L'IMPORT
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-card>
      </template>
    </TableViewComponent>

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import TableViewComponent from "@/components/ui/TableViewComponent.vue";

import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import {
  ObjectsService,
  DeleteObjectException,
} from "@/service/conf/transmission/objects_service.js";

import { TicketingService } from "@/service/backend/ticketing_service.js";

import * as exceptions from "@/service/exception_to_message.js";

import { defines as routes } from "@/router/defines.js";

import { roleCanEdit } from "@/service/role_service.js";

import { RolesApplicationEnum } from "@/service/roles/roles_application.js";


export default {
  name: "TableTransmissions",
  components: { TableViewComponent },
  mixins: [SnackBarMixin],
  data() {
    return {
      /**en cours de chargement */
      loading: false,

      /**les éléments */
      items: [],

      /**le service d'accès à l'api */
      service: null,

      /**le service d'accès au backend / ticketing */
      serviceTicketing: null,

      /** export/import en cours de traitement */
      running: false,

      /**échec export/import */
      failed: false,

      /**message échec export/import */
      failure: null,

      /**messages échec export/import */
      failureMessages: [],

      /**success export/import */
      success: false,

      /**message success export/import */
      successMessage: null,

      canEdit: false,

      /** url pour le gsheet d'import export */
      urlGsheet: null,

      /**afficher la popup de confirmation de l'import */
      showConfirmImport: false,
    };
  },
  methods: {
    onAddElement() {
      this.$router.push(routes.transmission.transmissions.add.path);
    },
    async load() {
      try {
        this.loading = true;

        this.items = [];

        // Récupère l'url du gsheet pour l'import export
        let urlgsheet = await this.serviceTicketing.getUrlGsheetTransmission();
        this.urlGsheet = urlgsheet.urlgsheet;


        let items = await this.service.loadObjects();

        items.forEach((r) => {
          let item = JSON.parse(JSON.stringify(r));

          //action de consultation
          item.view = () => {
            this.$router.push(
              routes.transmission.transmissions.view.root + "/" + item.id
            );
          };

          //action de modification
          item.edit = () => {
            this.$router.push(
              routes.transmission.transmissions.edit.root + "/" + item.id
            );
          };

          //action de suppression
          item.delete = async () => {
            try {
              await this.service.deleteObjectById(item.id);
              this.load();
            } catch (error) {
              console.error(error);

              if (error instanceof DeleteObjectException) {
                this.addErrorToSnackbar(
                  "Suppression impossible : veuiller d'abord retirer les sous-objets associés."
                );
              } else {
                this.addErrorToSnackbar(
                  "problème lors de la suppression : " +
                    (exceptions.toMessage(error) || "problème technique")
                );
              }
            }
          };

          this.items.push(item);

          //   console.log(JSON.stringify(this.items, null, 4));
        });
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "problème lors du chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**Construire les colonnes à afficher dans la vue table */
    buildColumns() {
      let columns = [
        {
          text: "Activités",
          align: "start",
          sortable: true,
          value: "nameActivities",
          default: true,
        },
        {
          text: "Objet",
          align: "start",
          sortable: true,
          value: "label",
          default: true,
        },
        {
          text: "Nombre de sous-objets",
          align: "start",
          sortable: false,
          value: "nbSubobjects",
          default: true,
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "center",
          width: "80px",
          default: true,
        },
      ];

      return columns;
    },

    async exportMatrix() {
      try {
        this.failed = false;
        this.running = true;
        this.failure = null;
        this.failureMessages = [];
        this.success = false;
        this.successMessage = null;

        await this.serviceTicketing.exportTransmissionMatrix();

        this.success = true;
        this.successMessage = "La matrice est exportée.";
      } catch (error) {
        this.failed = true;
        this.failure =
          "La matrice n'a pas pu être exportée vers un gsheet. " +
          error.response.data.message;
      } finally {
        this.running = false;
      }
    },

    /**confirmation d'importation */
    confirmImportMatrix() {
      this.showConfirmImport = false;
      this.importMatrix();
    },

    async importMatrix() {
      try {
        this.failed = false;
        this.running = true;
        this.failure = null;
        this.failureMessages = [];

        this.success = false;
        this.successMessage = null;

        await this.serviceTicketing.importTransmissionMatrix();

        this.success = true;
        this.successMessage =
          "La matrice est importée dans la Database Globale.";
      } catch (error) {
        this.failed = true;
        this.failure =
          "La matrice n'a pas pu être importée vers la Database Globale. " +
          error.response.data.message +
          ".";
        this.failureMessages = error.response.data.report.messages;
      } finally {
        this.running = false;
      }
    },
  },
  computed: {
    /**Retourne la liste des rôles attendus pour l'édition */
    rolesForEdition() {
      return [RolesApplicationEnum.EditConf];
    },
    /** Retourne la liste des rôles attendus pour l'ajout/suppression */
    rolesForAddDelete() {
      return [ RolesApplicationEnum.EditConf, ];
    },
    /**Retourne la liste des rôles attendus pour la lecture */
    rolesForRead() {
      return [RolesApplicationEnum.ReadConf];
    },
  },
  mounted() {
    this.service = new ObjectsService(
      this.$api.getTransmissionObjectsApi(),
      this.$api.getActivitiesApi()
    );

    this.serviceTicketing = new TicketingService(this.$api.getTicketingApi());

    this.canEdit = roleCanEdit();

    this.load();
  },
};
</script>

<style>
</style>